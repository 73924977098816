@import "minima";

.post-content { 
    text-align: justify;
}

.center-image {
    overflow: hidden;
    margin: 0 auto;
    display: block;
}

.drop-shadow {
    overflow: hidden;

    -webkit-filter: drop-shadow(.5rem .5rem 0.4rem rgb(145, 145, 145));
    -moz-filter: drop-shadow(.5rem .5rem 0.4rem rgb(145, 145, 145));
    -ms-filter: drop-shadow(.5rem .5rem 0.4rem rgb(145, 145, 145)); 
    -o-filter: drop-shadow(.5rem .5rem 0.4rem rgb(145, 145, 145));
    filter: drop-shadow(.5rem .5rem 0.4rem rgb(145, 145, 145)); 

    padding-top: 0.8em;
    padding-bottom: 1.8em;
}

.center-image-wider{
    overflow: hidden;
    max-width:140%;
    margin: 0 auto;
    margin-left: -19%;
}

.highlighter-rouge .highlight {
    background: #eef;
    max-height: 500px;
    margin-bottom: 25px;
    tab-size: 2;
    -moz-tab-size: 2;
}
.expand-sup{
    overflow: hidden;
    position: relative; 
    top: -0.70em;
    right: -7.4em;
    font-size: 70%;
    margin-left: -5.9em;

    opacity: 30%;
    // color: ;
}

// Adding 'Contents' headline to the TOC
#markdown-toc::before {
    content: "Contents";
    font-weight: bold;
    position: relative;
    top: -0.4em;
}

// Using numbers instead of bullets for listing
#markdown-toc ul {
    list-style: lower-roman;
}

#markdown-toc {
    border: 2px solid #7e7e7e;
    padding: 2.0em;
    padding-bottom: 0.6em;
    padding-top: 1em;
    list-style: upper-roman;
    margin: 0 auto;
    display: block;
    width: 24em;
    margin-bottom: 1.6em;
}
